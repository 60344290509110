<mat-expansion-panel hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h3 i18n>Quellen Filter</h3>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <section class="check-box-list-section">
  <span class="check-box-list-section">
    <mat-checkbox class="check-box-list-margin"
                  [checked]="allComplete"
                  [color]="source.color"
                  [indeterminate]="someComplete()"
                  (change)="setAll($event.checked)">
      Alle Quellen auswählen / abwählen
    </mat-checkbox>
  </span>
    <span class="check-box-list-section">
    <ul>
      <li *ngFor="let subsource of sourceList">
        <mat-checkbox [(ngModel)]="subsource.completed"
                      [color]="subsource.color"
                      (ngModelChange)="updateAllComplete()">
          {{subsource.userDisplayName}}
        </mat-checkbox>
      </li>
    </ul>
  </span>
  </section>
</mat-expansion-panel>

