import { Component } from '@angular/core';
import {QuestionService} from "../../services/question.service";
import {WhitelabelService} from "../../services/whitelabel.service";

@Component({
  selector: 'privacy-hint-hallucination-disclaimer',
  templateUrl: './privacy-hint-hallucination-disclaimer.component.html',
  styleUrls: ['./privacy-hint-hallucination-disclaimer.component.scss']
})
export class PrivacyHintHallucinationDisclaimerComponent {

  isBiblioSH = this.whiteLabelService.isBiblioSH

  constructor(
    public questionService: QuestionService,
    private whiteLabelService: WhitelabelService,
  ) {
  }
}
