import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { enableProdMode } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import {environment} from "./environments/environment";
import {defaultIntegrations, Integrations} from "@sentry/angular-ivy";
import {Integration} from "@sentry/types/types/integration";



Sentry.init({
  enabled: environment.production,
  dsn: "https://178a848066a71dcb282716046926dfee@o4505861223546880.ingest.sentry.io/4505861227937792",
  integrations: function (integrations) {
    // integrations will be all default integrations
    let integrationsToEnable: Integration[] = integrations.filter(function (integration) {
      return integration.name !== "Dedupe";
    });
    integrationsToEnable.push( new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.routingInstrumentation,
    }));
    integrationsToEnable.push( new Sentry.Replay() );
    return integrationsToEnable
  },
  // Performance Monitoring
  sampleRate: 1.0,
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
Sentry.setTag('whitelabel', environment.whitelabel)

enableProdMode();
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log('Bootstrap success'))
  .catch((err) => console.error(err));
